<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div>

    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <input v-model="filter.search" type="text" class="form-control" placeholder="Search...">
        </div>
      </div>
      <div class="col-md-8" />
    </div>

    <b-card-code no-body>
      <div class="table-responsive-sm" style="overflow-x: auto">
        <table class="table b-table table-fixed">
          <thead>
            <tr>
              <th>Sales</th>
              <th>Jumlah</th>
              <th>Status</th>
              <th>Tanggal</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr v-if="isLoading">
              <td colspan="5" class="text-center">
                <b-spinner label="Loading..." variant="primary" />
              </td>
            </tr>
            <tr v-for="item in result.data" v-if="!isLoading" :key="item">
              <td>{{ item.user.name + ' - ' + item.user.phone }}</td>
              <td>{{ formatAmount(item.amount) }}</td>
              <td style="text-transform: capitalize;">
                {{ item.status }}
              </td>
              <td>{{ item.created_at }}</td>
              <td class="text-right">
                <div class="btn-group" role="group" aria-label="Basic example">

                  <button class="btn btn-info btn-sm" style="margin-left: 2px;" data-toggle="tooltip"
                    data-placement="top" title="Detail" @click="detailItem(item)">
                    <feather-icon icon="EyeIcon" />
                  </button>

                  <button v-if="item.status == 'pending'" class="btn btn-success btn-sm" style="margin-left: 2px;"
                    data-toggle="tooltip" data-placement="top" title="Approve" @click="approve(item.uuid)">
                    <feather-icon icon="CheckCircleIcon" />
                  </button>

                  <button v-if="item.status == 'pending'" class="btn btn-danger btn-sm" style="margin-left: 2px;"
                    data-toggle="tooltip" data-placement="top" title="Reject" @click="reject(item.uuid)">
                    <feather-icon icon="XCircleIcon" />
                  </button>

                </div>
              </td>
            </tr>
            <tr v-if="result.total == 0 && !isLoading">
              <td colspan="5" class="text-center">
                Data is empty.
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row mt-1">
          <div class="col-6 pl-2">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col-6 pr-2">
            <pagination :data="result" :limit="4" align="right" @pagination-change-page="getData" />
          </div>
        </div>
      </div>
    </b-card-code>

    <!-- Detail Modal -->
    <b-modal id="detail-modal" no-close-on-backdrop size="lg" title="Detail">
      <table v-if="detail != null" class="table table-hover">
        <tr>
          <td>ID</td>
          <td>{{ detail.formatted_id }}</td>
        </tr>
        <tr>
          <td>Jumlah</td>
          <td>{{ formatAmount(detail.amount) }}</td>
        </tr>
        <tr>
          <td>Status</td>
          <td style="text-transform: capitalize;">
            {{ detail.status }}
          </td>
        </tr>
        <tr>
          <td>Sales</td>
          <td>{{ detail.user.name + ' - ' + detail.user.phone }}</td>
        </tr>
        <tr>
          <td>Rekening Pembayaran</td>
          <td>
            Nama Bank: {{ detail.user.sales_information.bank_name }} <br>
            Nomor Rekening: {{ detail.user.sales_information.bank_account_number }} <br>
            Nama Pemilik Rekening: {{ detail.user.sales_information.bank_account_name }}
          </td>
        </tr>
      </table>
    </b-modal>
    <!-- Detail Modal -->
  </div>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner,
  BFormGroup,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
import FileSaver from 'file-saver'
import axios from 'axios'

import 'vuejs-dialog/dist/vuejs-dialog.min.css'

Vue.use(VuejsDialog)

export default {
  title() {
    return 'Withdraw'
  },
  components: {
    BFormGroup,
    BCard,
    BButton,
    BCardText,
    BCardCode,
    BDropdown,
    BDropdownItem,
    BSpinner,
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      currentPage: 1,
      result: Object,
      isLoading: false,
      detail: null,
      filter: {
        search: '',
      },
    }
  },
  created() {
    this.getData()
  },
  methods: {
    formatAmount(value) {
      if (typeof value !== 'number') {
        return value
      }
      const formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      })
      return formatter.format(value).replace(/^(\D+)/, 'Rp ')
    },
    detailItem(item) {
      this.detail = item

      this.$bvModal.show('detail-modal')
    },
    getData(page = 1) {
      this.isLoading = true
      this.currentPage = page
      const queryParams = this.filter
      queryParams.page = this.currentPage

      this.$http.get('/super-admin/withdraw', {
        params: queryParams,
      })
        .then(response => {
          this.result = response.data.data
          this.isLoading = false
        })
    },
    approve(id) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to approve this withdraw?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.post(`super-admin/withdraw/${id}/approve`)
            .then(response => {
              this.getData(this.currentPage)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Withdraw successfully approved',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
    reject(id) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to reject this withdraw?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.post(`super-admin/withdraw/${id}/reject`)
            .then(response => {
              this.getData(this.currentPage)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Withdraw successfully rejected',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
  },
}
</script>
